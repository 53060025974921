import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { isMobile } from 'react-device-detect'
const windowGlobal = typeof window !== 'undefined' && window
const DesignsFilter = ({actvesec}) =>{
    const [status, setStatus] = useState( windowGlobal && windowGlobal.localStorage.getItem('DesignsFilter') ? windowGlobal.localStorage.getItem('DesignsFilter'):'true')
    
    return(
        <div className="blog-filter-section" style={{float:'unset',marginTop:"0px", paddingTop:'0px',paddingBottom:'0px',marginBottom:'0px'}}>
        <div className="filter-box-content" >
        <div className="category-filter-section filter-visible" >
        <div className="filter-box-click" style={{visibility:'hidden',height:'16px'}}>
          <a className="listing-page-back close-added" onClick={()=>{
              if(status === 'false'){
                setStatus('true')
                windowGlobal.localStorage.setItem('DesignsFilter','true')
              }else{
                setStatus('false')
                windowGlobal.localStorage.setItem('DesignsFilter','false')
              }
          }}>
              {status ==='false' ? `Show` : `Hide`} Filter{' '} 
          <i className={`fa fa-angle-${status ==='false' ? `down` : `up`}`}></i></a></div>

          <div className="p-data-show-type" style={{display:status ==='false' ? 'none' : 'block'}}>
            <div className="fliter-option cell-wrapper layout-widget-wrapper" style={{marginBottom:'35px'}}>
              <p className={`show-filter-cycle-lifecycle ${actvesec ==='/cases'?`active-element`:''}`} onClick={()=>navigate('/cases')}>
                {isMobile ? '' : 'Client'} Reviews</p>
              <p className={`show-filter-cycle-lifecycle ${actvesec ==='/designs'?`active-element`:''}`} onClick={()=>navigate('/designs')}>
              {isMobile ? '' : 'Product'} Designs</p>
            </div>
          </div>
        </div>
        </div>
        </div>
    )
}
export default DesignsFilter