import React from 'react'

const ListView = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="6962.548 4516 35.135 23">
        <g id="Group_496" data-name="Group 496" transform="translate(5231 4332)">
        <rect id="Rectangle_66" data-name="Rectangle 66" className="list-1" fill='#b2b2b2' width="26.682" height="5" transform="translate(1740 184)"></rect>
        <rect id="Rectangle_67" data-name="Rectangle 67" className="list-1" fill='#b2b2b2' width="5.452" height="5" transform="translate(1731.548 184)"></rect>
        <rect id="Rectangle_68" data-name="Rectangle 68" className="list-1" fill='#b2b2b2' width="26.682" height="5" transform="translate(1740 193)"></rect>
        <rect id="Rectangle_69" data-name="Rectangle 69" className="list-1" fill='#b2b2b2' width="5.452" height="5" transform="translate(1731.548 193)"></rect>
        <rect id="Rectangle_70" data-name="Rectangle 70" className="list-1" fill='#b2b2b2' width="26.682" height="5" transform="translate(1740 202)"></rect>
        <rect id="Rectangle_71" data-name="Rectangle 71" className="list-1" fill='#b2b2b2' width="5.452" height="5" transform="translate(1731.548 202)"></rect>
        </g>
        </svg>
    )
}
export default ListView;