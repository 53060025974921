import React from 'react'
import CasesPage from '../../templates/cases-page'
import { Link, graphql, StaticQuery } from 'gatsby'
export default ({location}) => (
    <StaticQuery
      query={graphql`
      query CasesList {
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "case-detail"}}}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                colorfeaturedimage {
                    childImageSharp{
                    fluid{
                      src
                    }
                  }
                }
                 uncolorfeaturedimage {
                  childImageSharp{
                    fluid{
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
      
      `}
      render={(data) => <CasesPage data={data} location={location}/>}
    />
  )
  