import React from 'react'
import {Link,navigate} from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
const CasesViewSection = ({data,kk})=>{
    return(
<div 
key={kk}
id={data.node.id} 
className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget">
    <div className="block_1" onClick={()=>navigate(`${data.node.fields.slug}`)}>
    <Link to={data.node.fields.slug} className="company1">
        <div className="non_active_logo">
        <PreviewCompatibleImage
            imageInfo={{
                childImageSharp: data.node.frontmatter.uncolorfeaturedimage.childImageSharp,
                alt: `${data.node.frontmatter.title}_uncolor_image`
                }}
            />
        </div>
        <div className="active_logo">
        <PreviewCompatibleImage
         imageInfo={{
            childImageSharp: data.node.frontmatter.colorfeaturedimage.childImageSharp,
            alt: `${data.node.frontmatter.title}_color_image`
            }}
        />
      </div>
    </Link>
    <div className="case-content en-text">
    <Link to={data.node.fields.slug} >   
    <h4> {data.node.frontmatter.title}</h4>
    <p>{data.node.frontmatter.description}</p>
    </Link>
    </div> 
</div>
</div>
    )
}
export default CasesViewSection;