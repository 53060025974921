import React from 'react'

const GridView = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="4987.548 4516 25.452 23">

<g id="Group_494" data-name="Group 494" transform="translate(4107 4343)">
<rect id="Rectangle_94" data-name="Rectangle 94" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(880.548 173)"></rect>
<rect id="Rectangle_95" data-name="Rectangle 95" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(890.548 173)"></rect>
<rect id="Rectangle_96" data-name="Rectangle 96" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(900.548 173)"></rect>
<rect id="Rectangle_97" data-name="Rectangle 97" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(880.548 182)"></rect>
<rect id="Rectangle_98" data-name="Rectangle 98" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(890.548 182)"></rect>
<rect id="Rectangle_99" data-name="Rectangle 99" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(900.548 182)"></rect>
<rect id="Rectangle_100" data-name="Rectangle 100" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(880.548 191)"></rect>
<rect id="Rectangle_101" data-name="Rectangle 101" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(890.548 191)"></rect>
<rect id="Rectangle_102" data-name="Rectangle 102" className="grid-1" fill="#b2b2b2" width="5.452" height="5" transform="translate(900.548 191)"></rect>
</g>
</svg>
    )
}
export default GridView;